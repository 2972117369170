import React from "react";
import { graphql, Link, PageProps } from "gatsby";
import NavHeader from "../components/NavHeader";

import "bootstrap-icons/font/bootstrap-icons.scss";
import "../styles.scss";
import { Container } from "react-bootstrap";

export const Page = ({ data }: PageProps<Queries.BlogPostsQuery>) => {
  return (
    <>
      <NavHeader />
      <Container>
        {data.allPrismicBlogPost.edges.map((edge) => (
          <React.Fragment key={edge.node.uid}>
            <Link to={`/blog/${edge.node.uid}`}>
              <h1>{edge.node.data.title?.text}</h1>
            </Link>
            <div
              dangerouslySetInnerHTML={{
                __html: edge.node.data.snippet?.html ?? "",
              }}
            />
          </React.Fragment>
        ))}
      </Container>
    </>
  );
};

export const query = graphql`
  query BlogPosts {
    allPrismicBlogPost {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            snippet {
              html
            }
          }
        }
      }
    }
  }
`;

export const Head = () => <title>Langsnap blog</title>;

export default Page;
